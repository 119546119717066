.input-min-width {
  @apply min-w-[120px] xl:min-w-[200px];
}

.input-max-width {
  @apply max-w-[200px];
}

.input-padding-x {
  @apply px-4;
}

.input-padding-y {
  @apply py-2;
}

.input-padding {
  @apply input-padding-x input-padding-y;
}

.input-border-color {
  @apply border-gray-300;
}

.input-placeholder {
  @apply placeholder-gray;
}

.input-border-radius {
  @apply rounded-md;
}

div[class~=input-placeholder] { /*trick tailwind*/
  @apply text-gray;
}

.input {
  @apply relative;
  @apply border border-gray-300;
  @apply shadow-sm;
  @apply text-darkest-gray;
  @apply bg-white;
  @apply border;
  @apply outline-none focus:outline-none;

  @apply input-placeholder;
  @apply input-border-color;
  @apply input-padding;
  @apply input-min-width;
  @apply input-border-radius;
}

.input--focus:not(.input--disabled) {
  @apply border-green;
}

.input:focus:not(.input--no-native-focus) {
  @apply input--focus;
}

.input-borderless {
  @apply border-transparent;
}

.input--unbound {
  @apply min-w-0;
}

.input-borderless.input-border-color, .input-borderless .input-border-color {
  @apply border-light-gray;
}

.input--valid {
}

.input--invalid {
  @apply border-red text-red placeholder-red focus:outline-none focus:ring-red focus:border-red;
}

.input--invalid input[class~=input-placeholder] {
  @apply placeholder-red;
}

.input--invalid div[class~=input-placeholder] { /*trick tailwind*/
  @apply text-red;
}

.input--disabled {
  @apply cursor-not-allowed bg-gray-100;
}

.input--loading {
  @apply cursor-progress;
}

.input-date--disabled {
  background-color:rgba(239, 239, 239, 0.3);
}

.input-warning {
  @apply absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none text-red;
}

.input-select:not(.input--loading) {
  @apply cursor-pointer;
}

.input-select-dropdown {
  @apply w-full;
  @apply absolute top-full left-0;
  @apply bg-white text-darkest-gray;
  @apply px-1.5 py-1;
  @apply shadow-sm;
  @apply border;
  @apply input-border-color;
  @apply input-border-radius;
  @apply flex flex-col gap-2;
  @apply overflow-y-auto;

  z-index: 1;
  min-height: 3rem;
  max-height: 20rem;
}

.input--loading ~ .input-select-dropdown {
  @apply cursor-progress;
}

.input-select-option {
  @apply cursor-pointer;
  @apply px-3.5 py-1.5;
  @apply text-left;
  @apply w-full;
  @apply flex flex-row gap-2 items-center;
  @apply rounded-md;
  @apply hover:bg-gray-200;
}

.input-select-option--selected {
  @apply bg-light-gray;
}

.input-select-option--disabled {
  @apply text-gray;
  @apply italic;
  @apply cursor-not-allowed pointer-events-none;
}

.input-select-dropdown--disabled {
  @apply hidden;

  z-index: 1;
}

.input-select-dropdown--loading {
  @apply cursor-progress;
}

.input-date-calendar {
  @apply p-4;
  @apply bg-white;
  @apply shadow-lg;
  @apply w-max;
  @apply input-border-radius;

  z-index: 1;
}

.input[type=number]::-webkit-outer-spin-button,
.input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input[type=number] {
  -moz-appearance: textfield;
}

.input-boolean {
  @apply border input-border-color;
  @apply p-1;
  @apply mt-2 mb-2;
  @apply rounded-full;
  @apply w-14 xl:w-12;
  @apply bg-white;
  @apply flex flex-row justify-center items-center;
}

.input-boolean:not(.input--disabled) {
  @apply cursor-pointer;
}

.input-boolean--active {
  @apply bg-green;
}

.input-boolean-switch {
  @apply w-4 h-4;
  @apply rounded-full;
  @apply bg-green;

  @apply transition-transform;
  @apply transform;
  @apply -translate-x-3/4;
}

.input-boolean--active .input-boolean-switch {
  @apply bg-white;
  @apply translate-x-3/4;
}

.input-boolean--active + .input-warning {
  @apply -right-0.5 top-0.5 text-sm;
}

.input-color {
  @apply input;
  @apply py-0.5;
  @apply flex flex-row items-center gap-4;
}

.input-color input[type=color] {
  --input-color-size: calc(2.5rem - 3.5px);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  height: var(--input-color-size);
  width: var(--input-color-size);
  background-color: transparent;
  border: none;
  cursor: pointer;
}
.input-color input[type=color]::-webkit-color-swatch {
  height: 100%;
  @apply input-border-color;
  @apply input-border-radius;
}
.input-color input[type=color]::-moz-color-swatch {
  height: 100%;
  @apply input-border-color;
  @apply input-border-radius;
}
